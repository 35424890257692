@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&family=Ysabeau:wght@1;100;200;300;400;500;600;700;800;900;1000&display=swap');

*{
    margin: 0;
    font-family: 'Ysabeau',sans-serif;
    box-sizing: border-box;
    text-decoration: none;
    outline: none;
    scroll-behavior: smooth;
  
  }

  #add-q-title{
    margin-bottom: 1rem;
    display: flex;

  }

  #add-q-title h2{
    margin-right: 1rem;
  }
  
  #title{
   background-color:#292826 ;
    text-align: center;
    padding-top: 1rem;
    color: white;
    padding-bottom: 1rem;
  }

  #add-question{
    height: auto;
    width: 100%;
    margin-bottom: 3rem;
    display: flex;
    margin-top: 2rem;
    justify-content: start;
    flex-direction: column;
    align-items: center;
  }

  .q-input{
    width: 200px;
  }

  #form{
    width: 100%;
    display: flex;
   
    flex-direction: column;
    align-items: center;
  }

  #spin{
   
    width: 100%;
   top: 45vh;
    
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 1;

  }

  .toast-container{
    z-index: 200;
  }